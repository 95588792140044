#list-captured {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  display: block;
  width: 100%;
}

#list-captured::-webkit-scrollbar {
  display: none;
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.home-progress-container {
  padding: 16px;
  background-color: rgb(24, 24, 24);
  border-radius: 10px;
  width: 100%;

}

.home-image-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 8px;
}

.home-reviews-title-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.progress-viewer-error-container {
  padding: 16px;
  color: white;
  height: 360px;
  width: 480px;
  border-radius: 3px;
  overflow: hidden;
  background-color: rgba(95, 95, 95, 0.4);
  backdrop-filter: blur(5px);
}

.progress-viewer-error-output {
  background-color: black;
  color: white;
  font-family: courier, monospace;
  font-weight: 600;
  overflow: scroll;
}

.progress-viewer-error-output {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.progress-viewer-error-output::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.scrollable-table-body {
  overflow: scroll;
}

.home-table {
  padding: 8px;
  width: 100%;
  background-color: rgb(27, 27, 27);
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.home-table th, .home-table td {
  /*background-color: rgb(27, 27, 27);*/
  padding: 8px 12px;
}

.home-table tbody {
  background-color: rgb(51, 51, 51);
}

.home-table-row  {
  margin: 2px;
}

.home-pane-container {
  width: 40vw;
}

.home-feed {
  width: 50%;
  margin: 8px;
}