.requests-form-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.request-location-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
}

.request-end-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.plugin-form-kv-input {
  margin: 4px;
}

.stage-ctrl {
  cursor: pointer;
  font-size: 16px;
}

.stage-ctrl:hover {
  color: rgb(65, 61, 61);
}

.request-parameters {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  flex-grow: 1;
  margin-right: 16px;
}

.fast-form-column {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  min-width: 160px;
}

.fast-form-content {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.label-form-pair {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.stage-forms-container {
  display: flex;
  direction: row;
  align-items: center;
  justify-content: space-between;
}

.custom-center-form-container {
  display: flex;
  flex-direction: column;
  max-width: 150px;
  padding-left: 32px;
  padding-right: 32px;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.custom-center-form-container input {
  width: 80px;
  margin: 4px;
  border: white 1px solid;
  color: white;
}

.custom-center-form-container label {
  color: white;
  font-weight: bold;
  margin: 4px;
}

.flexbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reason-label {
  color: indianred;
  width: 64px;
  font-size: 10px;
}

.command-bubble {
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 4px;
  border-radius: 100%;
  background-color: rgb(78, 78, 78);
  color: whitesmoke;
}

#fcf-start-container{
  display: flex;
  flex-direction: column;
}

#fcf-external-err-msg {
  color: indianred;
}

.fcp-sampling-save-preset {
  margin-left: 4px;
  font-size: 22px;
}

.fcp-sampling-save-preset:hover {
  color: gray;
  cursor: pointer;
}