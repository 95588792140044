.full-page-overlay-container {
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgba(197, 197, 197, 0.5);
  backdrop-filter: blur(10px);
}

.plugin-marketplace-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}
.plugin-marketplace-cards {
  overflow: scroll;
  height: calc(100vh - 240px);
}
.plugin-marketplace-card {
  display: flex;
  flex-direction: column;
  background-color: #202020;
  padding: 32px;
  border-radius: 12px;
  margin: 8px;
}
.plugin-chip {
    padding: 8px;
    background-color: gray;
    border-radius: 20px;
    cursor: pointer;
}

.plugin-chips {
  display: flex;
  justify-content: flex-start;
}

.stage-ctrl {
  cursor: pointer;
  font-size: 16px;
}

.stage-ctrl:hover {
  color: rgb(65, 61, 61);
}

.workspace-settings-icon {
  font-size: large;
  margin: 4px;
}

.workspace-name-form {
  font-weight: bolder;
  color: white;
  border: none;
  outline: none;
}

.workspace-name-form:hover {
  background-color: rgb(82, 82, 82);
}

.connection-form-container {
  background-color: #202020;
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.connection-form-row {
  display: flex;
  justify-content: space-around;
  align-content: stretch;
  align-items: center;
}

.single-end-aligned {
  display: flex;
  justify-content: flex-end;
}

.precondition-row {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 8px;
}

.connection-form-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
}

.selected-pane {
  border: 5px dodgerblue solid;
  border-radius: 5px;
}

.pane, .selected-pane {
  width: 120px;
  height: 120px;
  margin: auto;
  text-align: center;
}

.pane:hover {
  background: gray;
}

.micro-icon {
  margin: auto;
}

.micro-menu-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.conn-addr {
  margin-left: 4px;
}

.tooltip-inner {
  max-width: 100%;
}

.connection-bar-toggle-container {
  margin-left: 8px;

}

.connection-bar-menu-container {
  width: 300px;
  justify-content: flex-start;
  display: flex;
}

.manufacturer-select-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.manufacturer-select-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.manufacturer-logo {
  padding: 8px;
  margin: auto;
}

.manufacturer-logo-overlay, .manufacturer-logo-overlay-select {
  display: flex;
  align-items: center;
  margin: 8px;
  background-color: rgba(0,0,0,0.5);
  border-radius: 10px;
}

.manufacturer-logo-overlay-select {
  border: 3px solid dodgerblue;
}

.manufacturer-logo-overlay:hover, .manufacturer-logo-overlay-select:hover {
  background-color: rgba(197, 197, 197, 0.5);
}

.connection-select-container {
  display: flex;
  align-items: center;
}

.connection-select-main {
  margin-left: 8px;
  margin-right: 8px;
}

#conn-failing-menu-title {
  margin-right: 4px;
}

.conn-menu-error-title {
  border-bottom: 1px solid gray;
}

.conn-menu-details-container {
  margin-left: 16px;
}

.conn-menu-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.conn-menu-reconnect-btn {
  cursor: pointer;
}

.conn-menu-reconnect-btn:hover {
  color: gray;
}

.conn-menu-request-progress-container {
  display: flex;
  width: 100%;
  align-items: center;
}

.conn-menu-tabs {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.conn-menu-tab-active {

}

.conn-menu-tab-inactive {
  color: gray;
  cursor: pointer;

}

.conn-menu-tab-inactive:hover {
  color: lightgray;
}

.status-bar-conn-menu-btn, .status-bar-conn-menu-btn-open {
  padding-left: 16px;
  padding-right: 16px;
}

.status-bar-conn-menu-btn-open {
  background-color: black;
}

.status-bar-conn-menu-btn:hover, .status-bar-conn-menu-btn-open:hover {
  background-color: gray;
}

.conn-menu-menu-container {
  height: 500px;
  overflow: scroll;
}

.connection-setting-limit {
  display: flex;
  align-items: center;

}

.bound-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px;
  margin: 4px;
  width: 60px;
  border-radius: 20px;
}