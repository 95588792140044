.tab-active {
  height: 100%;
  background-color: gray;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  margin-right: 1px;
}

.tab-inactive {

}

.tab-name-inactive {
  color: white;
}

.tab-name-inactive:hover {
  cursor: pointer;
  color: white;
}

.tab-name-active {
  color: white;
}



.close-icon {
  margin-left: 8px;
  color: indianred;
}

.close-icon:hover {
  cursor: pointer;
  color: white;
}