.tabs-container {
  width: 100%;
  flex-grow: 10;
  padding: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.tabs-header {
  display: flex;
}

.tabs-button, .tabs-button-reverse {
  /*box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;*/
  
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: white;
  padding: 8px;
}

.tabs-button {
  background: none;
}

.tabs-button-reverse {
  background-color: #202020;
  border-radius: 4px 4px 0 0;
}

.tabs-content {
  margin: none;
  background-color: #202020;
  height: 100%;
  padding: 16px;
}