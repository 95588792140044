.dark-background {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  margin-bottom: 0px;
  padding-bottom: 24px;
  margin-left: 64px;
  margin-top: 64px;
  color: white;
  height: 100%;
}