.workspace-settings-container {
  display: flex;
  flex-direction: row;
}

.workspace-settings-list-container {
  width: 360px;
}

.workspace-settings-detail-container {
  padding: 16px;
  background-color: black;
  width: 100%;
}

.workspace-card {
  display: flex;
  padding: 4px;
  margin: 2px;
  background-color: rgb(34, 34, 34);
  border-radius: 8px;
}

.workspace-card-logo {
  padding: 8px;
}

.workspace-card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.workspace-card-title {
  font-weight: bold;
}

.feed-row-container {

}

.feed-text-col {
  width: 100%;
}

.feed-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

#settings-client-version {
  color: gray;
}

#settings-api-version {
  color: gray;
}

.db-command-result {
  overflow: scroll;
  
}

.settings-radio-menu {
  display: flex;
  align-items: center;
}

.archive-conf-el {
  display: flex;
}

.settings-archive-container {
  height: calc(100vh - 200px);
  overflow: scroll;
}

.general-settings-container {
  display: flex;
  justify-content: center;
}

.general-settings-version-container {
  padding: 32px;
  border-radius: 8px;
  background-color: rgb(54, 54, 54);
}

.general-settings-version-item {
  width: 240px;
  display: flex;
  justify-content: space-between;
}