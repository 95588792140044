.positive-sample {
  border: 2px solid greenyellow;
  border-radius: 2px;
}

.sample {
  margin: 2px;
}

.sample:hover {
  scale: 110%;
}

.bottom-right-container {
  position: absolute;
  bottom: 40px;
  right: 16px;
  display: flex;
  align-items: center;
}

.review-sample-patch {
  margin: 2px;
  border-radius: 2px;
}

.sample-review-next-btn {
  float: right;
  margin-left: 4px;
}

.sample-loading-container {
  height: calc(100vh - 260px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.sample-loading-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #989898;
  border-radius: 10px;
  padding: 72px;
  color: black;
  box-shadow: 2px 2px 3px darkgray;
}

.state-tabs-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.state-tab {
  padding: 4px;
  width: 120px;
  margin: 8px;
  text-align: center;
  cursor: default;
}

.retrospect-main-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.patch-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.patch-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
}

.patch-ctrl-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.patch-ctrl-overlay:hover {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.7); 
}

.patch-center-btn {
  display: none;
  font-size: 36px;
  cursor: pointer;
}

.patch-ctrl-overlay:hover .patch-center-btn {
  display: block;
}

.patch-confidence-container {
  display: none;
}

.patch-ctrl-overlay:hover .patch-confidence-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 4px;
  padding-right: 4px;
}
.single-confidence-container {
  display: flex;
  flex-direction: column;
}

.confidence-class-title {
  font-size: 10px;
}

.toolbar-container {
  position: absolute;
  width: 350px;
  top: 64px;
  right: 0;
  background-color: rgb(20, 20, 20);
  height: calc(100% - 24px - 64px);
  display: flex;
  flex-direction: column;
  padding: 4px;
}

.toolbar-view {
  flex-grow: 1;
  overflow: scroll;
}

.toolbar-view-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toolbar-key {
  color: gray;
}

.toolbar-value {

}

.toolbar-tools {
}

.review-root-container {
  
}