.tools-card {
  width: 192px;
  height: 120px;
  border-radius: 10px;
  background-color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.general-root {
  height: calc(100vh - 120px);
  overflow: scroll;
}

.general-root::-webkit-scrollbar {
  display: none;
}