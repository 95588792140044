.status-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 24px;
  background: #202020;
  padding-left: 8px;
  padding-right: 16px;
  z-index: 3;
  color: white;
  display: table-cell;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.status-bar-spaced-item {
  margin-left: 4px;
}

.status-bar-api-icon {
  margin-right: 4px;
}

.server-status-btn-open, .server-status-btn {
  padding-left: 16px;
  padding-right: 16px;
}

.server-status-btn:hover, .server-status-btn-open:hover {
  background-color: gray;
}

.server-status-btn-open {
  background-color: black;
}