/*.plate {

}*/

.plate, .plate-dis {
  fill: gray;
}

.plate:hover, .plate:focus, .plate:active, .plate-sel:hover, .plate-sel:focus, .plate-sel:active{
  fill: whitesmoke;
}

.plate-sel {
  fill: dodgerblue;
}