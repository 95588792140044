.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.navbar-button {
  color: #09d3ac;
  outline: none;
  background: none;
}

.sidebar-icon {
  color: white;
  cursor: pointer;
  text-align: center;
  font-size: 25px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.sidebar-icon:hover {
  color: whitesmoke;
}

html, body {
  background:  #303030;
  height: 100%;
  overflow: hidden;
}

.sidebar {
  position: absolute;
  top: 64px;
  left: 0;
  background: #404040;
  height: calc(100% - 24px - 64px);
  width: 64px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar-top {
  text-align: center;
  width: 64px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  
}

.sidebar-bottom {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 64px;
  justify-content: center;
}

.navbar-menu-item {
  padding: 8px 16px 8px 16px;
  height: 100%;
  margin-right: 8px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 10px;
}

.navbar {
  position: absolute;
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 64px;
  background: #202020;
  color: white;
  justify-content: space-between;
  padding-bottom: 0;
  padding-top: 0;
  overflow: hidden;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
}

.navbar-title {
  margin-left: 24px;
  margin-right: 24px;
  width: 128px;
  font-size: 28px;
  font-weight: bold;
  overflow: hidden;
}

.scrollable {
  max-height: calc(100vh - 200px);
  overflow: scroll;
}

.none {
  width: 100%;;
  display: flex;
  justify-content: center;
  align-items: center;

  height: calc(50vh - 128px);
}

.active-view {
  height: calc(60vh - 128px);
  width: 100%;
  overflow: scroll;
}

#user-icon {
  width: 40px;
  height: 40px;
}

#user-icon:hover {
  color: gray;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.active-view::-webkit-scrollbar {
  display: none;
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.menu, .menu-active {
  padding: 16px;
  height: 100%;
}

.menu-active {
  background-color: gray;
  border-radius: 10px;
}

/*admin app stuff below*/

.hidden {
  display: none;
}
/* Dropdown Button */
.dropdown-root {
  color: black;
  padding: 12px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 12px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

#dropdown-icon {
  font-size: 18px;
  margin-left: 8px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content .dropdown-element {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content .dropdown-element:hover {background-color: #ddd;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropdown-root {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.full-page-overlay-container {
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgba(197, 197, 197, 0.5);
  backdrop-filter: blur(10px);
  z-index: 3000;
}

.glass-pop-up {
  background-color: red;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.network-launcher {
  width: 180px;
  padding: 16px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 8px;
  background-color: rgba(255, 255, 255, 0.5);
}

.network-launcher-title {
  font-weight: bold;
  font-size: 24px;
}

.network-launcher-content {
  font-weight: lighter;
  
}

.network-launcher-action {
  margin-top: 16px;
  display: flex;
  flex-direction: row-reverse;
}

.network-launcher-action a {
  background-color: gray;
  color: white;
  padding: 8px;
  border-radius: 8px;
}

#launcher-play {
  margin-left: 8px;
}

.network-launchers-container {
  padding: 32px;
  display: flex;
}

.networks-title-bar {
  display: flex;
  align-items: center;
}

.networks-title {
  font-size: 28px;
  font-weight: bold;
}


#dashboard-help-button {
  font-size: 32px;
  border: 1px solid gray;
  border-radius: 100%;
  color: gray;
  padding: 2px;
  cursor: pointer;
  aspect-ratio: 1;
}

.dashboard-title-bar {
  display: flex;
  justify-content: space-between;
}

.dashboard-header {
  font-size: 32px;
  font-weight: bold;
}

.dashboard-container {
  padding: 8px;
  width: 80vw;
  height: 80vh;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

input {
  background: none;
  border: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 8px;
  padding: 8px;
}

.network-form-container {
  width: 400px;
}

.key-value-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
}

#network-create-button {
  border-radius: 100%;
  padding: 4px;
  font-size: 24px;
  aspect-ratio: 1;
  cursor: pointer;
  margin-left: 16px;
}

#network-create-button:hover {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

#close-network-form {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 100%;
  padding: 4px;
  font-size: 32px;
  aspect-ratio: 1;
  cursor: pointer;
}

#close-network-form:hover, .network-form-create-button:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.centering-container {
  display: flex;
  justify-content: center;
}

.network-client-card {
  border-radius: 16px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  background: #1d2b64; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1d2b64, #f8cdda); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1d2b64, #f8cdda); 
}

.network-client-card-title {
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.network-form-create-button {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background: none;
  font-size: 20px;
  border: none;
  padding: 8px;
  border-radius: 12px;
  cursor: pointer;
}

.login-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white; /*TODO blend with theme*/
}

.login-card {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 12px;
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.login-button {
  box-shadow: rgba(50, 50, 93, 0.4) 0px 2px 5px -1px, rgba(0, 0, 0, 0.5) 0px 1px 3px -1px;
  background: none;
  font-weight: bold;
  color: gray;
  border: none;
  border-radius: 12px;
  padding: 12px;
  cursor: pointer;
}

.login-button:hover {
  background-color: rgba(128, 128, 128, 0.308);
}

.button-icon {
  margin-right: 16px;
}

.login-card-title {
  font-size: 32px;
  margin-right: 32px;
  font-weight: bold;
}

.login-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.pill {
  background-color: indianred;
  box-shadow: rgba(50, 50, 93, 0.4) 0px 2px 5px -1px, rgba(0, 0, 0, 0.5) 0px 1px 3px -1px;
  border-radius: 12px;
  padding: 2px 8px;
  color: white;
  font-weight: bold;
  margin: 4px;
  font-size: 12px;
}

.login-card-footer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  width: 100%;
  margin-top: 32px;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.no-scrollbar::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}