.logs-view {
  overflow: scroll;
  height: /*calc(100% - 500px)*/calc(100vh - 120px);
}

.pagination-text {
  margin-left: 8px;
  margin-right: 8px;
}

.logs-query {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
}

.logs-query-label {
  margin-right: 8px;
  margin-left: 8px;
}

.logs-refresh {
  display: flex;
  align-items: center;
  width: 360px;
}

.logs-event-time {
  white-space: nowrap;
}

.logstable {
  width: 100%;
}

.logstable tr {
  display: block;
  border-radius: 12px;
  margin-bottom: 16px;
  background-color: none;
  padding: 16px 4px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
