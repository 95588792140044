.kv-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px;
}

.kv-input-label {
  margin-right: 8px;
  font-weight: bold;
}

.kv-input-label-group {

}

.kv-input {
  background: none;
  border: none;
  border-radius: 8px;
  padding: 8px;
  box-shadow: white 0px 1px 3px, white 0px 1px 2px;
  /*box-shadow: white 0px 2px 5px -1px, white 0px 1px 3px -1px;*/
  color: white;
}