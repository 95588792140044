.queries-row {
  display: flex;
}

.archive-table-container table {
  width: 100%;
}

.archive-table-container td {
  height: 40px;
  padding: 4px;
  background-color:rgb(20, 20, 20); /*#494949;*/
}

.table-row-filename {
  display: flex;
  justify-content: space-between;
}

.table-row-filename-open {
  margin-left: 8px;
  margin-right: 8px;
}

.archive-table-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#archive-pagination-text {
  margin-left: 8px;
  margin-right: 8px;
}


.pagination-control {
  color: whitesmoke;
}

.pagination-control:hover {
  color: lightgray;
}

.row-status-txt {
  width: 72px;
}

.row-date {
  width: 160px;
}

.toolbar-container {
  position: absolute;
  width: 350px;
  top: 64px;
  right: 0;
  background-color: rgb(20, 20, 20);
  height: calc(100% - 24px - 64px);
  display: flex;
  flex-direction: column;
  padding: 4px;
}

.toolbar-view {
  flex-grow: 1;
  overflow: scroll;
}

.toolbar-view-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
}

.toolbar-key {
  color: gray;
}

.toolbar-value {

}

.segmentation-img-grid-container-container {
  width: calc(100vw - 450px);
  height: calc(100vh - 24px - 64px - 16px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.segmentation-img-grid-container {
  width: calc(100% - 200px);
  height: calc(100vh - 24px - 64px - 16px );
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: scroll;
}


.segmentation-imgbox-container {
  display: flex;
  flex-direction: column;
  width: 350px;
  margin: 8px;
}

.segmentation-img-container {
  display: flex;
  justify-content: center;
  background-color: #202020;
  padding: 8px;
}

.segmentation-img-label {
 background-color: black;
 border-top-right-radius: 30px;
 padding: 4px;
}