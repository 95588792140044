.tooltip-container {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip-content {
  visibility: hidden;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  background-color: rgba(244, 244, 244, 0.557);
  backdrop-filter: blur(20px);
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.tooltip-container:hover .tooltip-content {
  visibility: visible;
}

.tooltip-root {

}